<template>
    <div class="alterPasswort">
      <rxNavBar :title="enterType==='password'?'修改密码':'修改管理密码'"></rxNavBar>
        <div class="passwordDiv" style="margin-top: 33px">
            <div class="passwordDot" :class="{'redColor':(userPasswordCurrent!='')}">•</div>
            <div class="text">当前密码</div>
            <div class="line">|</div>
            <input  :type="pwdTypeCurrent"  placeholder="请输入当前密码" @on-change="userPasswordCurrent"
                    onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'');"
                    maxlength="14" v-model="userPasswordCurrent"  class="again" />
            <img :src="seenCurrent?openeyeCurrent:nopeneyeCurrent" class="eye" alt="" @click="changeTypeCurrent()">
        </div>
        <div class="passwordDiv">
            <div class="passwordDot" :class="{'redColor':(userPasswordOne!='')}">•</div>
            <div class="text" >新密码</div>
            <div class="line">|</div>
            <input  :type="pwdTypeOne"   placeholder="请输入新密码" @on-change="userPasswordOne"
                    maxlength="12" v-model="userPasswordOne"  class="again"/>
            <img :src="seenOne?openeyeOne:nopeneyeOne" class="eye" style="padding-left: 13px;" alt="" @click="changeTypeOne()">
        </div>
            <div class="twice"  v-if="userPasswordOne && !/^[A-Za-z0-9]{6,12}$/.test(userPasswordOne)">请输入（6~12位的数字、字母组合）</div>
        <div class="passwordDiv" style="margin-top: 15px">
            <div class="passwordDot" :class="{'redColor':(userPasswordTwo!='')}">•</div>
            <div class="text">再次输入</div>
            <div class="line">|</div>
            <input  :type="pwdTypeTwo"  placeholder="请再次输入新密码" @on-change="userPasswordTwo"
                    maxlength="12" v-model="userPasswordTwo"  class="again" />
            <img :src="seenTwo?openeyeTwo:nopeneyeTwo" class="eye" alt="" @click="changeTypeTwo()">
        </div>
        <div class="twice"  v-if="userPasswordOne.trim().length!=0&&userPasswordTwo.trim().length!=0&&userPasswordOne!=userPasswordTwo">密码不一致</div>

      <div>
            <van-button class="login" :class="checkSave()?'loginColor':''"
                        :disabled="!checkSave()"
            v-on:click="passwordChange">


                确定
            </van-button>
        </div>
    </div>
</template>

<script>
    import {Button, Form, Field, NavBar, Toast} from 'vant';
    import {followUpList} from "../../getData/getData";
    import {globaluserId, responseUtil} from "../../libs/rongxunUtil";
    import {passwordChange,changeManagePassword} from '../../getData/getData';
    import rxNavBar from "../../components/rongxun/rx-navBar/navBar"
    export default {
        name: "alterPassword",
        data() {
            return {
                user_id: globaluserId,
                username: '',
                password: '',
                seenCurrent: '',
                pwdTypeCurrent: 'password', // 密码类型
                nopeneyeCurrent: require('../../assets/images/hidden.png'), //图片地址
                openeyeCurrent: require('../../assets/images/show.png'), //图片地址
                userPasswordCurrent: '',
                seenOne: '',
                pwdTypeOne: 'password', // 密码类型
                openeyeOne: require('../../assets/images/show.png'), //图片地址
                nopeneyeOne: require('../../assets/images/hidden.png'), //图片地址
                userPasswordOne: '',
                seenTwo: '',
                pwdTypeTwo: 'password', // 密码类型
                openeyeTwo: require('../../assets/images/show.png'), //图片地址
                nopeneyeTwo: require('../../assets/images/hidden.png'), //图片地址
                userPasswordTwo: '',

                enterType:'',
            };
        },
        components: {
            [Button.name]: Button,
            [Form.name]: Form,
            [Field.name]: Field,
            [NavBar.name]:NavBar,
          rxNavBar
        },
        mounted() {
            this.enterType = this.$route.params.type
        },
      methods:{

            //返回上一层
            leftReturn(){
                this.$router.go(-1);
            },

            changeTypeCurrent() {
                this.pwdTypeCurrent = this.pwdTypeCurrent === 'password' ? 'text' : 'password';
                this.seenCurrent = !this.seenCurrent;
            },
            changeTypeOne() {
                this.pwdTypeOne = this.pwdTypeOne === 'password' ? 'text' : 'password';
                this.seenOne = !this.seenOne;
            },
            changeTypeTwo() {
                this.pwdTypeTwo = this.pwdTypeTwo === 'password' ? 'text' : 'password';
                this.seenTwo = !this.seenTwo;
            },
            checkSave(){
              // console.log(/^[A-Za-z0-9]{6,12}$/.test(this.userPasswordOne))   //true符合要求
              if(this.userPasswordCurrent.trim().length!=0&&this.userPasswordOne.trim().length!=0&&this.userPasswordTwo.trim().length!=0
              && this.userPasswordTwo==this.userPasswordOne && /^[A-Za-z0-9]{6,12}$/.test(this.userPasswordOne)){
                return true
              }
              return false
            },
            passwordChange(){

                let that=this;
                if(that.userPasswordOne != that.userPasswordTwo ){
                    responseUtil.alertMsg(that,'两次输入密码不一致')
                    return
                }
                if(this.$route.params.type==='managePassword'){
                  this.managePasswordChange()
                  return;
                }
                let passwordChangeData={};

                passwordChangeData.user_id = globaluserId()
                passwordChangeData.oldPassword=that.userPasswordCurrent;
                passwordChangeData.newPassword=that.userPasswordOne;
                passwordChangeData.newPasswordConfirm=that.userPasswordTwo;

                passwordChange(passwordChangeData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response.data)
                        if(200==response.status){
                            responseUtil.alertMsg(that,'修改成功')
                          that.$router.go(-1)
                        }else {
                            responseUtil.alertMsg(that,'修改失败')
                        }
                    })
                })

            },
          managePasswordChange() {
              let that = this
              let passwordChangeData = {};

              passwordChangeData.user_id = globaluserId()
              passwordChangeData.oldManagePassword = that.userPasswordCurrent;
              passwordChangeData.newManagePassword = that.userPasswordOne;
              passwordChangeData.newConfirmManagePassword = that.userPasswordTwo;

              changeManagePassword(passwordChangeData).then(function (response) {
                  responseUtil.dealResponse(that, response, () => {
                      console.log(response.data)
                      if (200 == response.status) {
                          responseUtil.alertMsg(that, '修改成功')
                          that.$router.go(-1)
                      } else {
                          responseUtil.alertMsg(that, '修改失败')
                      }
                  })
              })
          }

      }
    }
</script>

<style scoped>
    .passwordDiv{
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        width: 92%;
        margin:15px 15px 0;
        font-size:16px;
        background:white;
        /*margin-top:30px;*/
        height: 50px;
        border-radius: 0.15rem;/*圆角*/
    }
    .passwordDot{
        display: inline-block;
        /*padding-top: 10px;*/
        margin-right: 10px;
       margin-left: 7px;
        width: 6px;
        /*height: 6px;*/
        color: #B8B8B8;
        font-size: 27px;
        float: left;
    }
    .text{
        white-space: nowrap;
        font-size: 14.5px;
        float: left;
        /*margin-top: 0.45rem;*/
        font-weight: bold;
        /*height: 0.5rem;*/
        font-family: PingFangSC-Semibold;
    }
    .line {
        font-size: 15px;
        display: inline-block;
        color: #EFEFEF;
        float: left;
        padding: 0px 13px 0px 13px;
    }

    .passwordDiv input {
        /*width: 5.3rem;*/
        flex: auto;
        float: left;
        /*margin-top: 0.485rem;*/
        border: none;
        font-size: 15px;
        vertical-align:middle;
    }
    /*显示和隐藏图标样式*/
    .eye{
        height: 10px;
        width: 20px;
        margin-right: 15px;
        /*margin-top: 0.58rem;*/
    }
    /*.again{*/
    /*    font-size: 15px;*/
    /*    border: none;*/
    /*    float: left;*/
    /*    !*width: 180px;*!*/
    /*  flex: auto;*/
    /*    !*margin-top: 16px;*!*/
    /*}*/
    /*.eye{*/
    /*    height: 0.25rem;*/
    /*    width: 0.55rem;*/
    /*    margin-right: 15px;*/
    /*    !*margin-top: 20px;*!*/
    /*}*/
    .login{
        margin: 240px 15px 0px 15px;
        width: 345px;
        height: 45px;
        font-size: 16px;
        background:rgba(184, 184, 184, 0.2);
        color: white;
        font-weight: bold;
        border-radius: 8px;
    }
    .redColor{
        color: red;
    }
    .loginColor{
        background:linear-gradient(to right,#ffc274,#ff5D3B) ;
    }


    .twice{
      font-size: 12px;
      width: 92%;
      margin: 0.2rem auto;
      color: rgba(255, 93, 59, 1);
      text-align: right;
      font-family: PingFangSC-Regular;
    }

</style>
